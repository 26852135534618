<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>Writer : {{ board.writer.name }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>Date : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>Views : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small" align="center">
                        <v-col cols="auto">Files :</v-col>
                        <v-col>
                            <v-chip-group>
                                <v-chip v-for="file in board?.files" :key="file?.name" :href="file.url" small outlined download>
                                    {{ file?.name }}
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div style="white-space:pre">{{ board.content }}</div>
                </td>
            </tr>
        </table>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col v-if="isUserWriter" cols="auto">
                    <v-row class="row--x-small">
                        <v-col cols="auto">
                            <v-btn small exact color="grey-6" class="w-100px" @click="$emit('remove')">Delete</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn small exact color="secondary" class="w-100px" :to="$route.path + '/modify'">Modify</v-btn>
                        </v-col>
                        <!-- <v-col cols="auto">
                            <v-btn small outlined exact color="grey-d" class="w-100px">답글</v-btn>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn small outlined exact color="grey-d" class="w-100px" @click="$router.go(-1)">List</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        board: { type: Object, default: null },
        isUserWriter: { type: Boolean, default: false },
    },
    data() {
        return {
            data: undefined,
            show: false,
        };
    },
    watch: {
        board() {
            if (this.board) this.show = true;
        },
    },
};
</script>
