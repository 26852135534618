var render = function render(){
  var _vm$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Writer : " + _vm._s(_vm.board.writer.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Date : " + _vm._s(_vm.board.createdAt.toDate()))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Views : " + _vm._s(_vm.board.viewCount))])])], 1)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("Files :")]), _c('v-col', [_c('v-chip-group', _vm._l((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.files, function (file) {
    return _c('v-chip', {
      key: file === null || file === void 0 ? void 0 : file.name,
      attrs: {
        "href": file.url,
        "small": "",
        "outlined": "",
        "download": ""
      }
    }, [_vm._v(" " + _vm._s(file === null || file === void 0 ? void 0 : file.name) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v(_vm._s(_vm.board.content))])])])]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_vm.isUserWriter ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "grey-6"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_vm._v("Delete")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "secondary",
      "to": _vm.$route.path + '/modify'
    }
  }, [_vm._v("Modify")])], 1)], 1)], 1) : _vm._e(), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "outlined": "",
      "exact": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("List")])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }